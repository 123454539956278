import React, { Component } from 'react'
import {
    // Container,
    Row,
    // Nav,
    // NavItem
} from 'reactstrap';
import 'semantic-ui-css/semantic.min.css'

export default class FooterComponent extends Component {
    render() {
        return (
            <footer className="bg-menu text-white w-100 h-100 m-0 p-0 py-5 px-5 justify-content-center">
                <div className="container">
                    <Row>
                        <div className="text-lg-center text-center pb-4 p-0 col-12">
                            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/asset/logo-MT-w.svg`} width="103px" alt="" />
                        </div>
                        <div className="col-md-4 col-12 py-md-0 pb-5 py-sm-2 d-none d-lg-block">
                            <div className="text-center pb-3">
                                <i className="material-icons-outlined fs-35 mx-3">room</i>
                                <h3 className="m-0 pl-2">ADDRESS</h3>
                            </div>
                            <div >
                                <div className="pb-3 text-center text-sm-left">
                                    <strong>Office</strong>
                                    <p className="pl-0 pt-2 d-flex font-weight-light">
                                        <a className="text-white" href="https://www.google.co.th/maps/place/%E0%B9%80%E0%B8%AD%E0%B9%87%E0%B8%A1.%E0%B8%97%E0%B8%B5.%E0%B8%8B%E0%B8%B1%E0%B8%9E%E0%B8%9E%E0%B8%A5%E0%B8%B2%E0%B8%A2/@13.6663751,100.4713279,19.6z/data=!4m5!3m4!1s0x30e2a2ed0521a2e7:0xc6a5f9a391032197!8m2!3d13.6663342!4d100.4711388?hl=th" target="_bank">
                                            59 Soi Phutthabucha 23, Bangmod, Jomthong, Bangkok 10150
                                        </a>
                                    </p>
                                </div>
                                <div className="pb-3 text-center text-sm-left">
                                    <strong>Factory</strong>
                                    <p className="pl-0 pt-2 d-flex font-weight-light">
                                        <a className="text-white" href="https://www.google.co.th/maps/place/M.T.+SUPPLY+CO.,LTD./@13.6681161,100.4693209,19z/data=!3m1!4b1!4m5!3m4!1s0x30e2a2ecd02a7b39:0xe4f786c16d761053!8m2!3d13.6681161!4d100.4698681?hl=th" target="_bank">
                                            2 Soi Phutthabucha 17, Bangmod, Jomthong, Bangkok 10150
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 py-md-0 pb-5 py-sm-2 d-none d-lg-block">
                            <div className="text-center pb-3">
                                <i className="material-icons-outlined fs-35 mx-3">drafts</i>
                                <h3 className="m-0 pl-2">MAIL</h3>
                            </div>
                            <div >
                                <div className="text-center">
                                    <p className="pl-0 font-weight-light">
                                        <a className="text-white" href="mailto:m.t.supply@hotmail.com" target="_bank">
                                            m.t.supply@hotmail.com
                                        </a>
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 col-12 py-md-0 pb-5 py-sm-2 d-none d-lg-block">
                            <div className="text-center pb-3">
                                <i className="material-icons-outlined fs-35 mx-3">phone</i>
                                <h3 className="m-0 pl-2">PHONE</h3>
                            </div>
                            <div >
                                <div className="pb-3 d-flex justify-content-center align-items-center">
                                    <strong>Tel : &nbsp;</strong>
                                    <p className="pl-0 d-flex font-weight-light">
                                        <a className="text-white" href="tel:02-428-3011-2" target="_bank">
                                            02-874-1594-5
                                        </a>
                                    </p>
                                </div>
                                <div className="pb-3 d-flex justify-content-center align-items-center">
                                    <strong>Phone : &nbsp;</strong>
                                    <p className="pl-0 d-flex font-weight-light">
                                        <a className="text-white" href="tel:081-985-4388" target="_bank">
                                            081-985-4388
                                        </a>
                                    </p>
                                </div>
                                <div className="pb-3 d-flex justify-content-center align-items-center">
                                    <strong>Fax : &nbsp;</strong>
                                    <p className="pl-0 d-flex font-weight-light">
                                        <a className="text-white" href="tel:02- 428-30 13" target="_bank">
                                            02-874-0040
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-3 d-md-flex" style={{ borderTop: "1.5px solid rgb(212, 212, 212)" }}>
                            <div className="py-2 col-md-6 col-12 text-md-left text-center">
                                <small>Copyright © 2019 All rights reserved by M.T. Supply Design by <a className="text-white" target="_blank" rel="noopener noreferrer" href="https://www.itreelife.com"><span >Itreelife.</span></a></small>
                            </div>
                            <div className="py-2 col-md-6 col-12 text-md-right text-center">
                                <a href="https://th-th.facebook.com/mtsupply/" className="text-white" target="_blank" rel="noopener noreferrer"><i className="mx-2 facebook f icon large"></i></a>
                                {/* <i className="mx-2 youtube icon large"></i> */}
                                <a href={`http://line.me/ti/p/~yuphin3917`} className="text-white" target="_blank" rel="noopener noreferrer"><i className="mx-2 linechat icon large" target="_blank" rel="noopener noreferrer"></i></a>
                            </div>
                        </div>
                    </Row>
                </div>
            </footer>
        )
    }
}

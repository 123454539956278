import React, { Component } from 'react'
import { Container } from 'reactstrap';
import './style.scss'

export default class Service extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                <section>
                    <div className="image-background-service" />
                    <Container>
                        <h1 className="pt-5 pb-0 text-blue header header-responsive">การบริการ</h1>
                        <div className="underline-header" />
                        <div className="underline-section" />
                        <div className="pt-3 pb-5">
                            <div className="pb-5">
                                <img src={`${process.env.PUBLIC_URL}/asset/05-About-Us/02-infographic-service-mt-supply.png`} alt="" className="d-block img-about-us mx-auto" />
                                <h2 className="text-blue header">มาตรฐานการกำหนดราคาบรรจุภัณฑ์ </h2>
                                <p className="pb-4 body-1">
                                    บริษัทเรามีมาตรฐานการทำราคาบรรจุภัณฑ์ ให้กับลูกค้า เพื่อให้ลูกค้าเกิดความไว้วางใจ และ ไม่มีนโยบายบวกราคาบรรจุภัณฑ์ เกินความเป็นจริง ทั้งนี้เพราะลูกค้าของเรามีหลากหลายธุรกิจ ทั้งแบบนำบรรจุภัณฑ์ ไปใช้เอง ทำเพื่อผลิตในระบบโรงงาน ธุรกิจประเภทสินค้าขายทั้งในประเทศ และส่งออก ผู้แทนจำหน่าย หรือ ผู้ที่นำบรรจุภัณฑ์ ไปกระจายต่อกับธุรกิจรายย่อยขนาดกลางถึงเล็ก ราคาบรรจุภัณฑ์ ของบริษัทเราสามารถเป็นต้นทุนที่นำไปแข่งขันในตลาดได้แน่นอน
                            </p>
                            </div>
                            <div className="pb-5">
                                <img src={`${process.env.PUBLIC_URL}/asset/05-About-Us/03-infographic-service-mt-supply.png`} alt="" className="d-block img-about-us mx-auto" />
                                <h2 className="text-blue header">การผลิตและการส่งมอบสินค้า</h2>
                                <p className="pb-4 body-1">
                                    เราดูแลการผลิตและการส่งมอบสินค้าที่ตรงต่อเวลา เพราะล้วนมีความสำคัญอย่างมาก ไม่ว่าจะเป็นลูกค้าที่ซื้อเพื่อการส่งออก หรือลูกค้าที่จำหน่ายภายในประเทศ เพราะนั้นคือโอกาสในการขายสินค้าของลูกค้า ในทางตรงกันข้าม หากเกิดปัญหาล่าช้าในการส่งมอบ ความเสียหายหรือความสูญเสียที่ตามมา จึงเป็นสิ่งสำคัญที่ไม่ควรมองข้ามไป
                            </p>
                            </div>
                            <div className="pb-5">
                                <img src={`${process.env.PUBLIC_URL}/asset/05-About-Us/01-infographic-service-mt-supply.png`} alt="" className="d-block img-about-us mx-auto" />
                                <h2 className="text-blue header">การบรรจุหีบห่อเพื่อการขนส่ง</h2>
                                <p className="body-1">
                                    การบรรจุหีบห่อ เป็นหัวข้อสำคัญที่มีผลต่อต้นทุนสินค้า และมีผลต่อความเสียหายแก่สินค้าได้ เนื่องจากสินค้ามีความละเอียดอ่อนเฉพาะตัว เช่น ไวต่อฝุ่น มีริ้วรอยขีดข่วนง่าย หรืออาจบุบเสียหายได้ ดังนั้นเราจึงมีการหีบห่อ แบบ 2 ลักษณะให้เลือก คือ แบบหีบห่อโดยใช้ถุงแยก, ถุงรวม และลังกระดาษ เป็นต้น
                                </p>
                                <p className="body-1 text-blue">
                                    นอกจากนี้ ความซื่อสัตย์ต่อข้อมูลเฉพาะของลูกค้า ไม่ว่าจะเป็น ตัวอย่างสินค้า สินค้าที่ยังไม่ออกสู่ตลาด จะต้องเก็บเป็นความลับอย่างดี เพราะหากเป็นสินค้าประเภทที่มีการแข่งขันสูง ข้อมูลเหล่านี้จะมีผลทางการตลาดทั้งสิ้น และนี้คือ<strong className="font-sarabun">หัวใจหลักที่ลูกค้าไว้วางใจในความซื่อสัตย์ของบริษัทเราได้ 100%</strong>
                                </p>
                            </div>
                            <img src={`${process.env.PUBLIC_URL}/asset/02-Service/04-infographic-service-mt-supply.png`} alt="" className="d-block img-about-us mx-auto" />

                        </div>
                    </Container>
                </section>
            </div>
        )
    }
}

import axios from 'axios';
// const apiPath = "https://itreelife.com/mt_supply/admin/wp-json/api/"
const apiPath = "https://mtsupplypackaging.com/admin/wp-json/api/"
const Service = {
    getBlog(e) {
        return axios.post(apiPath + 'getblog', e);
    },
    getSingleBlog(e) {
        return axios.post(apiPath + 'getsingleblog', e);
    },
    getRelatedBlog(e) {
        return axios.post(apiPath + 'getrelatedblog', e)
    },
    sendMailContact(e) {
        return axios.post(apiPath + 'sendmailcontact', e);
    },
    sendMailProduct(e) {
        return axios.post(apiPath + 'sendmailcontactproduct', e);
    }
}
export default Service
import React, { Component } from 'react';
import { Container, Collapse, CardBody, Card } from 'reactstrap';

export default class FAQ extends Component {
    constructor(props) {
        super(props)
        this.state = {
            _openColl: [
                {
                    q: "ถาม : ขนาดของบรรจุภัณฑ์ทรงกลม วัดอย่างไร ?",
                    a: "<div>ตอบ :&nbsp;</div> <div class='w-75'> วัดเส้นผ่าศูนย์กลาง (ซ้ายไปขวา) และวัดความสูง (บนลงล่าง) ของบรรจุภัณฑ์ทรงกลม โดยหน่วยวัดจะเป็น เซนติเมตร (ซ.ม.) หรือนิ้วก็ได้</div>",
                    colState: false
                },
                {
                    q: "ถาม : ขนาดของบรรจุภัณฑ์ทรงสี่เหลี่ยม วัดอย่างไร ?",
                    a: "<div>ตอบ :&nbsp;</div> <div class='w-75'>วัดความกว้าง ความยาว ความสูง (หรือความหนา) ของบรรจุภัณฑ์ทรงสี่เหลี่ยม โดยหน่วยวัดจะเป็นเซนติเมตร (ซ.ม.) หรือนิ้วก็ได้</div.",
                    colState: false
                },
                {
                    q: "ถาม : ความหนาของพลาสติกที่ผลิตบรรจุภัณฑ์มีขนาดเท่าไหร่บ้าง ?",
                    a: "<div>ตอบ :&nbsp;</div> <div class='w-75'>พลาสติกที่ผลิตบรรจุภัณฑ์มีขนาดมีขนาดความหนาเริ่มต้นที่ 0.20 มม. – 0.50 มม. ทั้งนี้ความหนาที่เหมาะสมจะขึ้นอยู่กับรูปแบบบรรจุภัณฑ์ และลักษณะการใช้งาน</div>",
                    colState: false
                },
                {
                    q: "ถาม : ใช้ระยะเวลาในการผลิตเท่าไหร่ ?",
                    a: "<div>ตอบ :&nbsp;</div> <div class='w-75'><ul><li>กรณีที่เป็นงานใหม่ จะใช้เวลาดำเนินการและการผลิตโดยประมาณ 15 – 20 วัน</li> <li>กรณีที่เป็นงานสต๊อก งานที่เคยสั่งผลิตมาแล้ว จะใช้เวลาในการผลิตประมาณ 3 – 15 วัน จะขึ้นอยู่กับจำนวนการสั่งซื้อ และประเภทงานนั้นๆ</li></ul> </div>",
                    colState: false
                },
                {
                    q: "ถาม : ค่าบล็อก ค่าโมลล์ มีค่าใช้จ่ายเพิ่มเติมหรือไม่ ?",
                    a: "<div>ตอบ :&nbsp;</div> <div class='w-75'><ul><li>ในกรณีที่มีบล็อก / โมลล์อยู่แล้ว จะไม่มีค่าใช้จ่ายในส่วนนี้</li> <li>ในกรณีที่ไม่มี หรือต้องผลิตบล็อก / โมลล์ใหม่ จะมีค่าใช้จ่ายเพิ่มเติม ขึ้นอยู่กับรูปแบบของชิ้นงาน</li></ul></div>",
                    colState: false
                },
                {
                    q: "ถาม : เวลาขนส่งสินค้าใช้บรรจุด้วยอะไร ? ",
                    a: "<div>ตอบ :&nbsp;</div> <div class='w-75'><ul><li>แบบที่ 1 บรรจุสินค้าใส่ถุง</li> <li>แบบที่ 2 บรรจุสินค้าใส่ลังกระดาษ </li></ul></div>",
                    colState: false
                }
            ]
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    handleShowDetail = (index) => {
        let { _openColl } = this.state
        _openColl[index].colState = !_openColl[index].colState
        this.setState({ _openColl: _openColl })
    }

    render() {
        return (
            <div>
                <section>
                    <div className="image-background-faq" />
                    <Container className="pt-2 pb-5">
                        <div className="pb-3 pb-md-5">
                            <h1 className="pt-5 pb-0 text-blue header-responsive">FAQ</h1>
                            <div className="underline-header" />
                            <div className="underline-section" />
                        </div>
                        {this.state._openColl.map((items, index) => {
                            return (
                                <div className="pb-3" key={index}>
                                    <Card className="card-collaps shadow-mt">
                                        <CardBody className="d-block d-sm-flex align-items-center justify-content-between">
                                            <strong className="text-blue"><p dangerouslySetInnerHTML={{ __html: items.q }}></p></strong>
                                            <div className="d-flex">
                                                <i className={`material-icons-outlined ml-auto d-sm-block fs-25 pointer text-blue ${items.colState ? "anime-rotate-180 " : "anime-rotate-0 "}`} onClick={e => this.handleShowDetail(index)}>play_circle_outline</i>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Collapse isOpen={items.colState}>
                                        <div className="py-3 px-4">
                                            <p className="d-flex" dangerouslySetInnerHTML={{ __html: items.a }}></p>

                                        </div>
                                    </Collapse>
                                </div>
                            )
                        })}
                    </Container>
                </section>
            </div>
        )
    }
}
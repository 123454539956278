import React, { Component } from 'react'
import { Container } from 'reactstrap';
// import './style.scss'

export default class Service extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <section style={{ height: "500px" }}>
                <Container style={{height:"100%"}}>
                    <div className="d-flex justify-content-center align-items-center" style={{height:"100%"}}>
                        <div className="text-center">
                            <i className="material-icons-outlined fs-90">sentiment_very_dissatisfied</i>
                            <h3 className="header">ไม่พบหน้าที่ต้องการ</h3>
                        </div>
                    </div>
                </Container>
            </section>
        )
    }
}

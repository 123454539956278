import React, { Component } from 'react'
import { Container, Row } from 'reactstrap';
import ButtonCat from '../../Components/ButtonCat';
import Lightbox from 'react-image-lightbox';
export default class Portfolio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            lightboxOpen: false,
            photoIndex: 0
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    getChildProps = (detail) => {
        this.setState({ data: detail })
    }
    handleChangePhotolightBox(index) {
        document.body.style.overflow = 'hidden'
        this.setState({ lightboxOpen: true, photoIndex: index })
    }
    render() {
        return (
            <section>
                <div className="image-background-portfolio" />
                <Container>
                    <div>
                        <h1 className="pt-5 text-blue header header-responsive">ผลงาน</h1>
                        <div className="underline-header" />
                        <div className="underline-section" />
                    </div>

                </Container>
                <div className="py-sm-4 py-3">
                    <ButtonCat getData={this.getChildProps} />
                </div>
                <Container className="pl-0 pb-5">
                    <Row className="pb-3">
                        {
                            this.state.data.image_product !== undefined &&
                            this.state.data.image_product.map((item, index) => {
                                return (
                                    <div className="col-md-4 col-6 mb-3 px-0 reps-product container-map" onClick={() => this.handleChangePhotolightBox(index)} key={index}>
                                        <img alt="" src={`${process.env.PUBLIC_URL}${item}`} className="mx-3 img-fluid image-map" />
                                        <div className="middle ml-3">
                                            <i className="material-icons text-white">zoom_in</i>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        {
                            this.state.data.image_product !== undefined &&
                            this.state.lightboxOpen &&
                            <Lightbox
                                mainSrc={`${process.env.PUBLIC_URL}${this.state.data.image_product[this.state.photoIndex]}`}
                                nextSrc={`${process.env.PUBLIC_URL}${this.state.data.image_product[(this.state.photoIndex + 1) % this.state.data.image_product.length]}`}
                                prevSrc={`${process.env.PUBLIC_URL}${this.state.data.image_product[(this.state.photoIndex + this.state.data.image_product.length - 1) % this.state.data.image_product.length]}`}
                                onCloseRequest={() => { this.setState({ lightboxOpen: false }); document.body.style.overflow = 'visible' }}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (this.state.photoIndex + this.state.data.image_product.length - 1) % this.state.data.image_product.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (this.state.photoIndex + 1) % this.state.data.image_product.length,
                                    })
                                }
                            />
                        }

                    </Row>
                </Container>


            </section>
        )
    }
}

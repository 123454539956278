import React, { Component } from 'react'
import { Container, Row, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Service from '../../service';
import { NavLink, withRouter } from 'react-router-dom'
import "./style.scss";
import Moment from 'react-moment';

import {
    FacebookIcon,
    FacebookShareButton,
    TwitterShareButton,
    TwitterIcon,
    LineShareButton,
    LineIcon
} from 'react-share';

class NewSingle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            dataSub: []
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        let _cat_id = [], _id_blog;
        Service.getSingleBlog({ "slug_name_post": this.props.match.params.key })
            .then(response => {
                if (response.data.data.length !== 0) {
                    this.setState({ data: response.data.data[0] })
                    response.data.data[0].cat_blog.some((items, index) => {
                        _cat_id.push(items.term_id)
                        return true;
                    })
                    _id_blog = response.data.data[0].id;
                    Service.getRelatedBlog({ "cat_ids": _cat_id, "id_blog": _id_blog, "limit": 3 })
                        .then(response => {
                            this.setState({ dataSub: response.data.data })
                        })
                    window.scrollTo(0, 0)
                } else {
                    this.props.history.push('/not-found-404')
                }

            })

    }

    handleChangeItems = (id) => {
        let _cat_id = [];
        Service.getSingleBlog({ "slug_name_post": id })
            .then(response => {
                response.data.data[0].cat_blog.forEach((items, index) => {
                    _cat_id.push(items.term_id)
                })
                Service.getRelatedBlog({ cat_ids: _cat_id, id_blog: response.data.data[0].id, limit: 3 })
                    .then(res => {
                        window.scrollTo(0, 0)
                        this.setState({ data: response.data.data[0], dataSub: res.data.data,  })
                    })
            })
    }
    render() {
        return (
            <div>
                <section>
                    <Container>
                        <div className="py-3">
                            <Breadcrumb>
                                <BreadcrumbItem><NavLink exact to="/" >หน้าแรก</NavLink></BreadcrumbItem>
                                <BreadcrumbItem active>บทความ</BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                        <h1 className="text-blue header" dangerouslySetInnerHTML={{ __html: this.state.data.title }}></h1>
                        <div className="py-3 d-flex align-items-center">
                            <div className="img-thumnail">
                                <img src={this.state.data.authornameimage} alt="" />
                            </div>
                            <small className="caption">{this.state.data.authorname} | <Moment format="YYYY/MM/DD">{this.state.data.date}</Moment> </small>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="blog-style-for-worldpress d-inline-block">
                                <img src={this.state.data.img_blog_thumbnail} alt="" className="pb-3" />
                                <div className={`col-lg-12 ${this.state.data.id === undefined && "min-height-100"} font-sarabun `} dangerouslySetInnerHTML={{ __html: this.state.data.description }} />
                            </div>
                        </div>


                        <Row>
                            <div className="col-lg-7 d-flex align-items-center py-5 social-content">
                                <strong>SHARE:</strong>
                                <FacebookShareButton url={`${document.location.href}`}>
                                    <FacebookIcon borderRadius={50} size={33.37} />
                                </FacebookShareButton>
                                <TwitterShareButton url={`${document.location.href}`}>
                                    <TwitterIcon borderRadius={50} size={33.37} />
                                </TwitterShareButton>
                                <LineShareButton url={`${document.location.href}`}>
                                    <LineIcon borderRadius={50} size={33.37} />
                                </LineShareButton>
                            </div>
                        </Row>
                        <div>
                            <h1 className="pt-5 text-blue header header-responsive">บทความที่เกี่ยวข้อง</h1>
                            <div className="underline-header" />
                            <div className="underline-section" />
                        </div>
                        <Row className="py-3">
                            {
                                this.state.dataSub.length !== 0 ?
                                    this.state.dataSub.map((items, index) => {
                                        return (
                                            <div className="col-lg-4 col-12 py-3" key={index}>
                                                <div>
                                                    <NavLink to={`/news/${items.sub_url_name}`} onClick={(e) => this.handleChangeItems(items.sub_url_name)}>
                                                        <div className="hov-img-zoom">
                                                            <img src={`${items.img_blog_thumbnail}`} className="img-fluid pb-3" alt="" />
                                                        </div>
                                                        <h3 className="mt-0 mb-2 text-title-blog text-blue" dangerouslySetInnerHTML={{ __html: items.title }}></h3>
                                                    </NavLink>
                                                    <p className="body-1 text-descriptionmore" dangerouslySetInnerHTML={{ __html: items.descriptionmore }}></p>
                                                </div>
                                                <div className="py-3 d-flex align-items-center">
                                                    <div className="img-thumnail">
                                                        <img src={items.authornameimage} alt="" />
                                                    </div>
                                                    <small>{items.authorname} | <Moment format="YYYY/MM/DD">{items.date}</Moment></small>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                    (
                                        <div className="col-12">
                                            <h1 className="text-center py-5" style={{ color: "gray" }}>ยังไม่มีบทความอื่นๆ</h1>
                                        </div>
                                    )
                            }

                        </Row>
                    </Container>
                </section>
            </div >
        )
    }
}
export default withRouter(NewSingle)
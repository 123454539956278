import React, { Component } from 'react'
import { Row, Container } from 'reactstrap';
import './style.scss';
import "react-id-swiper/lib/styles/scss/swiper.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import myData from '../../data/data.json';

const settings = {
    centerPadding: '40px',
    slidesToShow: 1,
    infinite: true,
}
class ButtonCat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: myData.data,
            activeIndex: 0,
        }
        // this.init_historyback()

    }
    init_historyback() {
        const mq = window.matchMedia("(max-width: 991.89px)");
        if (mq.matches) {
            window.onpopstate = function () {
                window.history.go(1);
            };
            window.history.pushState(null, null, window.location.href);
        }
    }
    componentDidMount() {
        this.setState({ activeIndex: this.props.indexProduct ? this.props.indexProduct : 0 })
        this.props.getData(myData.data[this.props.indexProduct ? this.props.indexProduct : 0])
    }

    handleClick = (items, index) => {
        this.props.getData(items)
        this.setState({ activeIndex: index })
    }

    SlideChange = (index) => {
        this.props.getData(this.state.data[index])
        this.setState({ activeIndex: index })
    }

    render() {
        return (
            <div>
                <Container className={`my-3 d-none d-lg-block`}>
                    <Row>
                        {
                            this.state.data.map((items, index) => {
                                return (
                                    <div className={`px-1 button-cat-root pointer`} key={index} onClick={e => this.handleClick(this.state.data[index], index)}>
                                        <div className={`py-4 bg-blue text-white text-center ${this.state.activeIndex === index && "active-button-type"}`}>
                                            <img className="h-75 pb-3" src={`${process.env.PUBLIC_URL}/asset/Icon-type-products-mt/0${index + 1}-icon-type-product.svg`} alt="" />
                                            {/* <i className="material-icons-outlined fs-40">card_giftcard</i> */}
                                            <div className="fs-16 px-2">{items.title}</div>
                                            <div>{items.title_eng}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Row>
                </Container>
                <Container>
                <div className="my-3 d-lg-none d-block">
                    {
                        this.state.data !== null &&
                        <Slider {...settings} afterChange={e => this.SlideChange(e)}>
                            {
                                this.state.data.map((items, index) => {
                                    return (
                                        <div className="button-cat-root pointer px-2" key={index}>
                                            <div className={`py-4 px-2 bg-blue text-white text-center ${this.state.activeIndex === index ? "active-button-type" : ""}`}>
                                                <img className="h-75 pb-3 mx-auto" src={`${process.env.PUBLIC_URL}/asset/Icon-type-products-mt/0${index + 1}-icon-type-product.svg`} alt="" />
                                                <div>{items.title}</div>
                                                <div>{items.title_eng}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    }
                </div>
                </Container>
            </div>

        )
    }
}
export default ButtonCat;
import React, { Component } from 'react'
import { Container } from 'reactstrap';

export default class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <section>
                <div className="image-background-aboutus" />
                <Container className="py-5 font-sarabun">
                    <div>
                        <img src={`${process.env.PUBLIC_URL}/asset/logo-MT.svg`} width="139px" alt="" className="d-block mx-auto" />
                        <h3 className="text-center text-blue">M.T. SUPPLY CO.,LTD.</h3>
                        <p className="text-center text-blue pb-5">“ผู้เชี่ยวชาญการผลิต และการบริการด้านบรรจุภัณฑ์ครบวงจรต้องเป็นเรา M.T. SUPPLY CO.,LTD. ด้วยประสบการณ์กว่า 27 ปีที่ผ่านมา เรามุ่งเน้นพัฒนาการออกแบบ และการผลิตเพื่อให้ผลิตภัณฑ์สามารถตอบสนองความต้องการของลูกค้าได้มากที่สุด”</p>
                    </div>
                    <div className="content-text pl-0 ">
                        <p>เราใส่ใจในทุกรายละเอียด เพราะในมุมมองของเราความพึงพอใจของลูกค้าคือ ความใส่ใจในรายละเอียดของเราที่ส่งมอบให้ลูกค้าอย่างพิถีพิถัน และพร้อมพัฒนา ปรับปรุง แก้ไข เพื่อให้เกิดประโยชน์สูงสุดในการใช้ผลิตภัณฑ์ที่มีคุณภาพดี</p>
                    </div>
                    <div className="content-text pl-0 ">
                        <p>ก้าวแรกของเราเริ่มต้นเมื่อปี พ.ศ.2535 จากห้องแถวเล็กๆ กับกลุ่มบุคคลเพียงไม่กี่คนที่มาพร้อมกับความรู้ ประสบการณ์ด้านงานบรรจุภัณฑ์ และจิตใจอันเป็นหนึ่งที่อยากจะสร้างความพึงพอใจให้กับลูกค้าของเรา จึงเริ่มพัฒนาผลิตภัณฑ์จากวัตถุดิบ PVC นิ่ม ออกแบบมาหลากหลายรูปแบบไม่ว่าจะเป็นซอง PVC, ถุงผ้าปู, ถุงผ้านวม, ถุงใส่อาหารสุนัข ทั้งแบบใส และแบบสี จากคุณภาพที่เราใส่ใจจึงได้รับความพึงพอใจจากลูกค้าเป็นอย่างดี</p>
                    </div>
                    <div className="content-text pl-0 ">
                        <p>พ.ศ.2536 เราเริ่มขยายฐานผลิตของเราจากห้องแถว 1 คูหาเพิ่มขึ้นอีก 2 คูหาพร้อมพัฒนาผลิตภัณฑ์ให้มีลวดลายบนบรรจุภัณฑ์ของเรา อีกทั้งยังออกแบบรูปแบบบรรจุภัณฑ์แบบใหม่ๆอีกด้วย</p>
                    </div>
                    <div className="content-text pl-0 ">
                        <p>เพื่อให้เกิดการบริการที่ครบวงจรเราได้เปิดโรงงานผลิตที่มีแผนกขึ้นรูป PVC, PS และแผนกเย็บจักรอุตสาหกรรมขึ้นในปี พ.ศ.2537 เพื่อให้สามารถผลิตบรรจุภัณฑ์ที่ตอบสนองความต้องการของลูกค้าได้อย่างดีที่สุด โดยเน้นคุณภาพจากการผลิตที่เป็นมาตรฐาน ใส่ใจทุกรายละเอียดตั้งแต่เริ่มต้นจนถึงมือลูกค้า</p>
                    </div>

                    <div className="content-text pl-0 ">
                        <p>ด้วยความไว้วางใจที่เราได้รับจากลูกค้าอย่างต่อเนื่อง ด้วยการบริการที่มุ่งเน้นตอบสนองความต้องการของลูกค้าเป็นที่ตั้ง จึงทำให้เราได้ก้าวไปอีกก้าวหนึ่งในปี พ.ศ.2545 กับการขยายโรงงานผลิต เปิดแผนกบลิสเตอร์แพค สไลด์แพค ปรับปรุงกระบวนการผลิตให้มีความทันสมัยมากยิ่งขึ้น ลดความผิดพลาดของกระบวนการผลิต ยกระดับคุณภาพผลิตภัณฑ์ขึ้นไปอีกขั้น ส่งมอบสิ่งดีๆให้ลูกค้าของเราจนถึงปัจจุบัน</p>
                    </div>

                    <div className="content-text pl-0 ">
                        <p>เป้าหมายสูงสุดของเราคือ ความพึงพอใจของลูกค้า ทุกการก้าวเดินสู่จุดหมายล้วนมีสิ่งที่ให้เรียนรู้ ไม่ใช่แค่ประสบการณ์ของเราที่พร้อมส่งมอบให้แก่ลูกค้า แต่เราพร้อมส่งมอบบริการที่ดีจากเราให้แก่คุณ เพราะเราคือผู้เชี่ยวชาญด้านงานบรรจุภัณฑ์ครบวงจรตัวจริง</p>
                    </div>
                </Container>
            </section>
        )
    }
}

import React, { Component } from 'react';
import { Pagination } from 'semantic-ui-react';
import './style.scss'
export default class PaginationComponent extends Component {
    render() {
        return (
            <Pagination
                // boundaryRange={this.props.current}
                // defaultActivePage={this.props.current}
                activePage={this.props.current}
                onPageChange={this.props.onChangePage}
                size='mini'
                boundaryRange={0}
                ellipsisItem={null}
                prevItem={{ content: <i className="material-icons-outlined">chevron_left</i>, icon: false }}
                nextItem={{ content: <i className="material-icons-outlined">chevron_right</i>, icon: false }}
                // siblingRange={2}
                totalPages={this.props.total}
            />
        );
    }
}
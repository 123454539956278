import React from 'react';
import ReactDOM from 'react-dom';
// import ReactDomServer from 'react-dom/server';
import App from './App';
import * as serviceWorker from './serviceWorker';

// const mq = window.matchMedia("(min-width: 767px)");
//         if (mq.matches) {
//             window.on('navigator', function (e, data) {
//                 let direction = data.state.direction;
//                 if (direction === 'back') {
//                     alert('back')
//                 }
//             })
//         }

// ReactDomServer.renderToString(<App />, document.getElementById('root'));
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

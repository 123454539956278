import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./style/main.scss";
import "./style/util.scss";
import NavbarComponent from "./Components/Navbar";
import FooterComponent from "./Components/Footer";

import HomePage from "./Containers/Home";
import ServicePage from "./Containers/Service";
import PortfolioPage from "./Containers/Portfolio";
import AboutUsPage from "./Containers/AboutUs";
import TypeProductPage from "./Containers/TypeProduct";
import NewPage from "./Containers/New";
import NewSinglePage from "./Containers/NewSingle";
import ContactUsPage from "./Containers/ContactUs";
import FAQPage from "./Containers/FAQ";
import NotFoundPage from "./Containers/NotFound";

import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    return (
      <Router>
        <FloatingMenu
          slideSpeed={500}
          direction="up"
          spacing={8}
          isOpen={this.state.isOpen}
          className="button-action-menu d-lg-none"
        >
          <MainButton
            iconResting={
              <i className="material-icons icon-image-preview text-white">
                
                subject
              </i>
            }
            iconActive={
              <i className="material-icons icon-image-preview text-white">
                
                clear
              </i>
            }
            backgroundColor="black"
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            size={56}
          />
          <ChildButton
            icon={
              <i className="material-icons icon-image-preview text-white">
                
                smartphone
              </i>
            }
            backgroundColor="white"
            size={40}
            onClick={() => window.open("tel:081-985-4388")}
          />
          <ChildButton
            icon={
              <i className="material-icons icon-image-preview text-white">
                
                phone
              </i>
            }
            backgroundColor="white"
            size={40}
            onClick={() => window.open("tel:02-874-1594")}
          />
          <ChildButton
            icon={
              <i className="material-icons icon-image-preview text-white">
                
                mail
              </i>
            }
            backgroundColor="white"
            size={40}
            onClick={() => window.open("mailto:m.t.supply@hotmail.com")}
          />
          <ChildButton
            icon={
              <i className="material-icons icon-image-preview text-white">
                
                room
              </i>
            }
            backgroundColor="white"
            size={40}
            onClick={() =>
              window.open(
                "https://www.google.co.th/maps/place/M.T.+SUPPLY+CO.,LTD./@13.6681161,100.4693209,19z/data=!3m1!4b1!4m5!3m4!1s0x30e2a2ecd02a7b39:0xe4f786c16d761053!8m2!3d13.6681161!4d100.4698681?hl=th"
              )
            }
          />
        </FloatingMenu>
        <NavbarComponent />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/service" component={ServicePage} />
          <Route exact path="/portfolio" component={PortfolioPage} />
          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/type-product" component={TypeProductPage} />
          <Route exact path="/news" component={NewPage} />
          <Route exact path="/news/:key" component={NewSinglePage} />
          <Route exact path="/contact-us" component={ContactUsPage} />
          <Route exact path="/faq" component={FAQPage} />
          <Route exact path="/not-found-404" component={NotFoundPage} />
          <Route component={HomePage} />
        </Switch>
        <FooterComponent />
      </Router>
    );
  }
}

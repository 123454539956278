import Joi from 'joi-browser'

const param = Joi.object().keys({
    subject: Joi.string(),
    name: Joi.string(),
    email: Joi.string().email({ minDomainAtoms: 2 }),
    tel: Joi.number(),
    message: Joi.string(),
})

const email_param = Joi.string().email({ minDomainAtoms: 2 })

export function Validate(obj, name_valid) {
    if (name_valid === "email_valid") {
        return Joi.validate(obj, email_param)
    } else {
        return Joi.validate(obj, param)
    }
}

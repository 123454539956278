import React, { Component } from 'react'
import { Container, Row, Spinner } from 'reactstrap';
import Pagination from '../../Components/pagination';
import Service from '../../service';
import { NavLink } from 'react-router-dom';
import Moment from 'react-moment';
export default class New extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            current: 1,
            max_page: 1,
            str_search: "",
            loadingItems: false
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0)
        Service.getBlog({ cat_name: "blog", limit: 9 })
            .then(response => {

                this.setState({ data: response.data.data, max_page: response.data.max_num_pages, current: response.data.paged, loadingItems: true })
            })
    }
    handleChangePagination = (e, { activePage }) => {
        window.scrollTo(0, 0)
        let _parseInt = parseInt(activePage)
        // this.setState({ changePage: false })
        this.setState({ loadingItems: false })
        Service.getBlog({ cat_name: "blog", limit: 9, page: _parseInt, search: this.state.str_search })
            .then(response => {
                this.setState({ current: _parseInt, data: response.data.data, loadingItems: true })
                window.scrollTo(0, 0)
            })
    }
    handleSearch = (e) => {
        if (e.keyCode === 13) {
            this.setState({ loadingItems: false })
            let _str = e.target.value
            Service.getBlog({ cat_name: "blog", limit: 9, search: _str })
                .then(response => {
                    this.setState({ current: response.data.paged, max_page: response.data.max_num_pages, data: response.data.data, str_search: _str, loadingItems: true })
                    window.scrollTo(0, 0)
                })
        }
    }
    render() {
        return (
            <section className="pb-3 pt-5">
                <Container className="min-height-100 position-relative">
                    <div className="row pb-3 px-3">
                        <div className="col-md-3 col-12 d-flex pl-0 align-items-center">
                            <h1 className="text-blue header header-responsive">บทความ</h1>
                        </div>
                        <div className="col-md-6 px-md-3 px-0 py-md-0 pt-3 pb-0 pb-sm-3 col-12 d-flex align-items-center">
                            <div className="d-flex align-items-center w-100">
                                <input className="w-100 input-search py-3" placeholder="ค้นหาบทความ" onKeyDown={(e) => this.handleSearch(e)} />
                                <i className="icon-search-ele pos-absolute material-icons-outlined" >search</i>
                            </div>
                        </div>
                    </div>
                    <div className="underline-header" />
                    <div className="underline-section" />
                    <Row className="pt-3 pb-5">
                        {
                            this.state.loadingItems ?
                                this.state.data.length !== 0 ?
                                    this.state.data.map((items, index) => {
                                        return (
                                            <div className="col-lg-4 col-md-6 col-12 pb-3 py-md-3 d-flex flex-column justify-content-between" key={index}>
                                                <div>
                                                    <NavLink to={`/news/${items.sub_url_name}`}>
                                                        <div className="hov-img-zoom mb-3">
                                                            <img src={items.img_blog_thumbnail} className="img-fluid " alt="" />
                                                        </div>
                                                        <h3 className="mt-0 mb-2 text-title-blog text-blue" dangerouslySetInnerHTML={{ __html: items.title }}></h3>
                                                    </NavLink>
                                                    <p className="body-1 text-descriptionmore" dangerouslySetInnerHTML={{ __html: items.descriptionmore }}></p>
                                                </div>
                                                <div className="py-3 d-flex align-items-center">
                                                    <div className="img-thumnail">
                                                        <img src={items.authornameimage} alt="" />
                                                    </div>
                                                    <small className="caption">{items.authorname} | <Moment format="YYYY/MM/DD">{items.date}</Moment> </small>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                    <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: "300px" }}>
                                        <h1 className="text-center " style={{ color: "gray" }}>ไม่พบบทความ</h1>
                                    </div>
                                :
                                <div className="d-block mx-auto p-t-100 min-height-100" style={{ height: "300px" }}>
                                    <Spinner color="info" />
                                </div>
                        }

                    </Row>
                    {
                        this.state.data.length !== 0 &&
                        <div className="d-flex justify-content-center ab-c-b ">
                            <Pagination current={this.state.current} total={this.state.max_page} onChangePage={this.handleChangePagination} />
                        </div>
                    }

                </Container>
            </section>
        )
    }
}

import React, { Component } from 'react'
import { Container } from 'reactstrap';
import { Helmet } from "react-helmet";
import ButtonCat from '../../Components/ButtonCat';
export default class Product extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    getChildProps = (detail) => {
        this.setState({ data: detail })
    }

    render() {
        return (
            <section className="pb-5">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>โรงงานผลิต กล่อง PVC | กล่องพลาสติกใส กล่อง PET | บลิสเตอร์แพค กล่องใส</title>
                    <meta name="author" content="M.T.SUPPLY" />
                    <meta name="title" content="รับผลิต กล่องใส  | กล่องพลาสติกใสนิ่ม บรรจุภัณฑ์พลาสติก | กล่อง PVC" />
                    <meta name="description" content="MT SUPPLY ผู้เชี่ยวชาญการผลิตบรรจุภัณฑ์พลาสติก กล่องใส กล่อง PVC กล่องพลาสติกใสนิ่ม กล่อง PET กล่องพลาสติกใส่ขนม บลิสเตอร์แพค สไลด์แพค  ถุง PVC ซอง PVC สำหรับใส่อาหารและสินค้า ได้มาตรฐานโรงงานการผลิตมีด้วยประสบการณ์เกือบ 30 ปี" />
                </Helmet>
                <div className="image-background-product" />
                <Container>
                    <div>
                        <h1 className="pt-5 text-blue header header-responsive">บรรจุภัณฑ์</h1>
                        <div className="underline-header" />
                        <div className="underline-section" />
                    </div>
                </Container>
                <div className="py-sm-4 py-3">
                    <ButtonCat getData={this.getChildProps} indexProduct={this.props.location.params !== undefined && this.props.location.params.typeProduct} />
                </div>
                <Container>
                    <h3 className="text-green pb-3 pb-md-4 m-0 header">ตัวอย่าง&nbsp;{this.state.data.title}&nbsp;{this.state.data.title_eng}</h3>
                    <div className="w-100 mb-5" dangerouslySetInnerHTML={{ __html: this.state.data.detail }}>

                    </div>
                    {
                        this.state.data.data_table !== undefined &&
                        this.state.data.data_table.map((items, index) => {
                            return items.map((it, id) => {
                                return (
                                    <div key={id} className="pb-3">
                                        <h3 className="text-center header text-blue">{it.title}</h3>
                                        <table className="table table-detail-product mx-auto table-bordered">
                                            <thead>
                                                <tr className="text-center">
                                                    {
                                                        it.head.map((text, num) => {
                                                            return <th scope="col" key={num}>{text}</th>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    it.body.map((size, indexSize) => {
                                                        return (
                                                            <tr className="text-center" key={indexSize}>
                                                                {
                                                                    size.map((text, num) => {
                                                                        return <td key={num} className="font-sarabun">{text}</td>
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })

                        })
                    }

                </Container>
            </section>
        )
    }
}

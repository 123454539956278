import React, { Component } from "react";
import { Container, Row, FormGroup, Input, FormFeedback } from 'reactstrap';
import GoogleMapReact from 'google-map-react'
import { Validate } from '../../Components/validate';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import Service from '../../service'
import swal from '@sweetalert/with-react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'

export default class ContactUs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tokenKey: "",
            data: {
                name: "",
                email: "",
                tel: "",
                message: "",
                subject: "none"
            },
            valid: {},
            alert: null,
            toggleMap: true,
            lightboxOpen: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        loadReCaptcha("6Le8nd4UAAAAADpigDfUlvuKz0FvUqVRTMAt7GZx");
    }

    sendMail = () => {
        if (Validate(this.state.data).error === null) {
            Service.sendMailContact({ ...this.state.data, recaptcha: this.state.tokenKey })
                .then(response => {
                    swal("ส่งอีเมล", `ระบบได้ทำการส่งอีเมลเรียบร้อยแล้ว`, "success")
                    let _data = this.state.data
                    _data = { ..._data, name: "", email: "", tel: "", message: "" }
                    this.setState({ data: _data })
                    window.location.reload()
                })
        } else {
            this.setState({ alert: "กรุณากรอกข้อมูลให้ครบถ้วน" })
        }


    }

    verifyCallback = (recaptchaToken) => {
        this.setState({ tokenKey: recaptchaToken })
    }

    renderMarkers(map, maps) {
        new maps.Marker({
            position: { lat: 13.668097, lng: 100.469898 },
            map,
            title: 'Sirivirin'
        });
    }

    onDismiss = () => { this.setState({ alert: null }) }

    handleChange = (e, state_name) => {
        this.onDismiss()

        let _data = this.state.data
        _data[state_name] = e.target.value
        const validate = Validate(this.state.data)
        if (validate.error !== null) {
            for (var i = 0; i < validate.error.details.length; i++) {
                this.setState({ data: _data, valid: { [validate.error.details[i].path[0]]: true } })
            }
        } else {
            this.setState({ data: _data, valid: {} })
        }
    }
    render() {
        return (
            <section >
                <div className="image-background-contact" />
                <Container>
                    <div>
                        <h1 className="pt-5 text-blue header header-responsive">ติดต่อเรา</h1>
                        <div className="underline-header" />
                        <div className="underline-section" />
                    </div>
                    <Row className="d-flex pt-5">
                        <div style={{ width: "100%", maxHeight: "334px" }} className="col-sm-7 order-1 order-lg-0">
                            {
                                !this.state.toggleMap ?
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyCHric3e0md16jr2IPdoIxRpnZTLu4PoOY" }}
                                        defaultCenter={{ lat: 13.668097, lng: 100.469898 }}
                                        defaultZoom={16}
                                        onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        style={{ height: "334px" }}
                                    >
                                    </GoogleMapReact> :
                                    <div className="container-map" onClick={e => { this.setState({ lightboxOpen: true }); document.body.style.overflow = 'hidden' }}>
                                        <img src={`${process.env.PUBLIC_URL}/asset/06-Contact/map-mt.jpg`} alt="Avatar" className="image-map" style={{ width: "100%" }} />
                                        <div className="middle">
                                            <i className="material-icons">zoom_in</i>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="col-sm-5 pb-3 pb-lg-0 text-blue d-flex align-items-center order-0 order-lg-1">
                            <div>
                                <div className="d-block">
                                    <div className="mx-auto mx-sm-0 pb-3 pb-sm-2 d-flex align-items-center justify-content-center justify-content-sm-start">
                                        <i className="material-icons-outlined fs-35">room</i>
                                        <h3 className="m-0 pl-2">ADDRESS</h3>
                                    </div>
                                    <div className="pl-sm-5 pl-sm-2 text-center text-sm-left">
                                        <div className="pb-3">
                                            <strong className="">Office</strong>
                                            <p className=" pl-0 font-weight-lighter">
                                                <a style={{ color: "#0295c4" }} href="https://www.google.co.th/maps/place/%E0%B9%80%E0%B8%AD%E0%B9%87%E0%B8%A1.%E0%B8%97%E0%B8%B5.%E0%B8%8B%E0%B8%B1%E0%B8%9E%E0%B8%9E%E0%B8%A5%E0%B8%B2%E0%B8%A2/@13.6663751,100.4713279,19.6z/data=!4m5!3m4!1s0x30e2a2ed0521a2e7:0xc6a5f9a391032197!8m2!3d13.6663342!4d100.4711388?hl=th" target="_bank">
                                                    59 ซอย พุทธบูชา 23 แขวง บางมด เขตจอมทอง กรุงเทพมหานคร 10150
                                            </a>
                                            </p>
                                        </div>
                                        <div>
                                            <strong className="">Factory</strong>
                                            <p className=" pl-0 font-weight-lighter">
                                                <a style={{ color: "#0295c4" }} href="https://www.google.co.th/maps/place/M.T.+SUPPLY+CO.,LTD./@13.6681161,100.4693209,19z/data=!3m1!4b1!4m5!3m4!1s0x30e2a2ecd02a7b39:0xe4f786c16d761053!8m2!3d13.6681161!4d100.4698681?hl=th" target="_bank">
                                                    2 ซอย พุทธบูชา 17 แขวง บางมด เขตจอมทอง กรุงเทพมหานคร 10150
                                            </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block pt-3">
                                    <div className="mx-auto mx-sm-0 pb-3 pb-sm-2 d-flex align-items-center justify-content-center justify-content-sm-start">
                                        <i className="material-icons-outlined fs-35">phone</i>
                                        <h3 className="m-0 pl-2">PHONE</h3>
                                    </div>
                                    <div className="pl-sm-5 pl-sm-2">
                                        <div className="d-flex justify-content-center justify-content-sm-start">
                                            <strong className="">Tel :&nbsp;</strong>
                                            <p className=" pl-0 font-weight-light">
                                                <a style={{ color: "#0295c4" }} href="tel:02-874-1594-5" target="_bank">
                                                    02-874-1594-5
                                                </a>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-center justify-content-sm-start">
                                            <strong className="">Phone :&nbsp;</strong>
                                            <p className=" pl-0 font-weight-light">
                                                <a style={{ color: "#0295c4" }} href="tel:081-985-4388" target="_bank">
                                                    081-985-4388
                                                </a>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-center justify-content-sm-start">
                                            <strong className="">Fax :&nbsp;</strong>
                                            <p className=" pl-0 font-weight-light">
                                                <a style={{ color: "#0295c4" }} href="tel:02-874-0040" target="_bank">
                                                    02-874-0040
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-block pt-3">
                                    <div className="mx-auto mx-sm-0 pb-3 pb-sm-2 d-flex align-items-center justify-content-center justify-content-sm-start">
                                        <i className="material-icons-outlined fs-35">drafts</i>
                                        <h3 className="m-0 pl-2">MAIL</h3>
                                    </div>
                                    <div className="pl-sm-5 pl-sm-2">
                                        <p className=" pl-0 font-weight-light text-center text-sm-left">
                                            <a style={{ color: "#0295c4" }} href="mailto:m.t.supply@hotmail.com" target="_bank">
                                                m.t.supply@hotmail.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div className="d-flex justify-content-center justify-content-lg-start pb-5 pt-3">
                        <button className={`button-gradient ${!this.state.toggleMap ? "button-gradient-active" : ""} mr-3`} onClick={e => this.setState({ toggleMap: false })}>Google Map</button>
                        <button className={`button-gradient ${this.state.toggleMap ? "button-gradient-active" : ""}`} onClick={e => this.setState({ toggleMap: true })}>Map Graphic</button>
                    </div>
                    <div className="form-contact">
                        <Row className="pt-2 pb-3">
                            <div className="col-lg-6">
                                <FormGroup className={'validate-input ' + (this.state.valid.name ? 'alert-validate' : '')}>
                                    <Input invalid={this.state.valid.name ? true : false} className="w-100 input-fire" onChange={e => this.handleChange(e, "name")} placeholder="Enter Your Name " value={this.state.data.name} />
                                    <FormFeedback>กรอกชื่อ-นามสกุล</FormFeedback>
                                </FormGroup>
                                <FormGroup className={'validate-input ' + (this.state.valid.email ? 'alert-validate' : '')}>
                                    <Input invalid={this.state.valid.email ? true : false} className="w-100 input-fire" onChange={e => this.handleChange(e, "email")} placeholder="Enter Your Email Address " value={this.state.data.email} />
                                    <FormFeedback>อีเมลไม่ถูกต้อง</FormFeedback>
                                </FormGroup>
                                <FormGroup className={'validate-input mb-0 ' + (this.state.valid.tel ? 'alert-validate' : '')}>
                                    <Input invalid={this.state.valid.tel ? true : false} className="w-100 input-fire" onChange={e => this.handleChange(e, "tel")} placeholder="Enter Your  Phone number" value={this.state.data.tel} />
                                    <FormFeedback>เบอร์โทรศัพท์ไม่ถูกต้อง</FormFeedback>
                                </FormGroup>
                            </div>
                            <div className="col-lg-6">
                                <FormGroup className={'validate-input h-100 mt-3 mt-lg-0 ' + (this.state.valid.message ? 'alert-validate' : '')}>
                                    <Input type="textarea" invalid={this.state.valid.message ? true : false} className="w-100 h-100 input-fire" onChange={e => this.handleChange(e, "message")} placeholder="Enter Your Message" value={this.state.data.message} />
                                    <FormFeedback>ข้อความไม่ถูกต้อง</FormFeedback>
                                </FormGroup>
                                {/* <textarea className="w-100 h-100 input-fire" onChange={e => this.handleChange(e, "message")} placeholder="Enter Your Message" /> */}
                            </div>
                        </Row>
                        <div className="w-100 mt-3 mt-lg-0">
                            <button className="button-gradient d-block m-auto ml-md-auto " onClick={() => this.sendMail()}>ส่งข้อความ</button>
                        </div>
                        <ReCaptcha
                            sitekey="6Le8nd4UAAAAADpigDfUlvuKz0FvUqVRTMAt7GZx"
                            verifyCallback={(res) => this.verifyCallback(res)}
                        />
                    </div>
                </Container>
                {this.state.lightboxOpen && (
                    <Lightbox
                        mainSrc={`${process.env.PUBLIC_URL}/asset/06-Contact/map-mt.jpg`}
                        onCloseRequest={() => { this.setState({ lightboxOpen: false }); document.body.style.overflow = 'visible' }}
                    />
                )}
            </section>
        )
    }
}
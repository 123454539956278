import React, { Component } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    // NavbarBrand,
    Nav,
    NavItem,
    // NavLink
} from 'reactstrap';
import { withRouter } from 'react-router-dom'

class NavbarComponent extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            navFixed: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll = (event) => {
        if (window.scrollY >= 115) {
            if ((window.scrollY >= 115)) {
                this.setState({ navFixed: true })
            }
        } else if (window.scrollY < 115) {
            if ((window.scrollY <= 115)) {
                this.setState({ navFixed: false })
            }
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <div style={this.state.navFixed ? { marginTop: "114px" } : { marginTop: "0" }}>
                <div className={`${this.state.navFixed && "fixed-top bg-white shadow w-100 d-block animate-nav-down transition"}`}>
                    <div className="container">
                        <div className="row align-items-lg-stretch align-items-center">
                            <div className="mx-0">
                                <a href="/">
                                    <img className="img-logo ml-3" src={`${process.env.PUBLIC_URL}/asset/logo-MT.svg`} alt="" />
                                </a>
                            </div>
                            <NavbarToggler className="ml-auto d-lg-none d-block mx-2" onClick={this.toggle} />
                            <div className="ml-auto d-lg-block d-none">
                                <div className="row h-100 justify-content-end align-items-center text-right">
                                    <div>
                                        <div className="col-12 text-blue pb-3">
                                            <span>Follow</span>
                                            <a href="https://th-th.facebook.com/mtsupply/" target="_blank" rel="noopener noreferrer"><i className="mx-2 facebook f icon large"></i></a>
                                            {/* <a href="https://www.youtube.com/channel/UCe1m0vps9pv6PkJEzS495OQ/featured" target="_blank" rel="noopener noreferrer"><i className="mx-2 youtube icon large"></i></a> */}
                                            <a href="http://line.me/ti/p/~yuphin3917"><i className="mx-2 linechat icon large" target="_blank" rel="noopener noreferrer"></i></a>
                                        </div>
                                        <div className="col-12 d-flex">
                                            <div className="text-left px-2">
                                                <span className="mr-2 text-blue font-weight-bold">TEL :</span> <a href="tel:02-874-1594" className="text-blue"> 02-874-1594-5</a>
                                            </div>
                                            <div className="text-left px-2">
                                                <span className="mr-2 text-blue font-weight-bold">PHONE :</span> <a href="tel:081-985-4388" className="text-blue"> 081-985-4388</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Navbar color="light" light expand="lg" className="px-lg-5 py-2 py-sm-3 bg-navbar" onScroll={e => this.scrollFade(e)}>
                        {/* <NavbarToggler className="ml-auto" onClick={this.toggle} /> */}
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="container ml-auto w-100 justify-content-lg-around text-center nav-na12" navbar>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname === '/') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/">หน้าหลัก</a>
                                    </NavItem>
                                </div>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname === '/service') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/service">บริการ</a>
                                    </NavItem>
                                </div>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname === '/type-product') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/type-product">บรรจุภัณฑ์</a>
                                    </NavItem>
                                </div>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname === '/portfolio') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/portfolio">ผลงาน</a>
                                    </NavItem>
                                </div>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname === '/about-us') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/about-us">เกี่ยวกับเรา</a>
                                    </NavItem>
                                </div>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname.split('/')[1] === 'new') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/news">บทความ</a>
                                    </NavItem>
                                </div>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname === '/contact-us') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/contact-us">ติดต่อเรา</a>
                                    </NavItem>
                                </div>
                                <div className="pos-relative">
                                    <NavItem className={"py-1 " + ((this.props.location.pathname === '/faq') ? 'active-menu' : 'menu-hover')} >
                                        <a className="text-white" href="/faq">FAQ</a>
                                    </NavItem>
                                </div>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>
            </div>
        )
    }
}

export default withRouter(NavbarComponent)